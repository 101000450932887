import { SET_REGISTRATION_DATA } from "../constants/constant"



export const setRegistrationData = (data = [], action) => {
    switch (action.type) {
            case SET_REGISTRATION_DATA:
                console.warn("SET_REGISTRATION_DATA condition ", action)
                return action.data
        default:
            return data
    }
}
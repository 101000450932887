import { takeEvery, put } from 'redux-saga/effects'
import { REGISTER, SET_REGISTRATION_DATA } from '../constants/constant';
import env from '../../env';

function* registerUser(action) {
  const url = `${env.BASE_URL}`;
  let data = yield fetch(url + '/api/v1/register/', {
    method: 'POST', // or 'PUT'
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(action.data),
  });
  data = yield data.json();
  console.warn("registered in user data", data)
  yield put({ type: SET_REGISTRATION_DATA, data })
}

function* registerSaga(cred) {
  yield takeEvery(REGISTER, registerUser)
}

export default registerSaga;
import React, { Component, Suspense, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import ProtectedRoute from './components/Auth/ProtectedRoute'
import LoginRoute from './components/Auth/LoginRoute'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const AdminLayout = React.lazy(() => import('./layout/AdminLayout'))
const SuperAdminLayout = React.lazy(() => import('./layout/SuperAdminLayout'))
const UserLayout = React.lazy(() => import('./layout/UserLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Home = React.lazy(() => import('./views/pages/home/Home'))
const PrivacyPolicy = React.lazy(() => import('./views/pages/home/PrivacyPolicy'))
const Terms = React.lazy(() => import('./views/pages/home/Terms'))
const ThankYou = React.lazy(() => import('./views/pages/ThankYou'))
const AboutUs = React.lazy(() => import('./views/pages/home/AboutUs'))
const ContactUs = React.lazy(() => import('./views/pages/home/ContactUs'))
const Disclaimer = React.lazy(() => import('./views/pages/home/Disclaimer'))
const Blogs = React.lazy(() => import('./views/blogs/pages'))

const Property = React.lazy(() => import('./views/pages/property/Property'))
const PropertyView = React.lazy(() => import('./views/pages/property/PropertyView'))
const PostView = React.lazy(() => import('./views/blogs/pages/post'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const MicroSite = React.lazy(() => import('./views/property/microsite/MicroSite'))

const App = () => {

  const [userType, setUserType] = useState(null);
  const checkAuthentication = () => {
    let temp = localStorage.getItem("userType");
    setUserType(temp);
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  return (
    <Router>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/" name="Home Page" element={<Home />} />
          <Route exact path="/home" name="Home Page" element={<Home />} />
          <Route exact path="/about-us" name="AboutUs Page" element={<AboutUs />} />
          <Route exact path="/contact-us" name="ContactUs Page" element={<ContactUs />} />
          <Route exact path="/thank-you" name="ThankYou Page" element={<ThankYou />} />

          <Route exact path="/privacy-policy" name="Privacy Polict" element={<PrivacyPolicy />} />
          <Route exact path="/terms-conditions" name="Terms Page" element={<Terms />} />
          <Route exact path="/disclaimer" name="Disclaimer Page" element={<Disclaimer />} />
          <Route exact path="/blogs" name="Privacy Polict" element={<Blogs />} />

          <Route exact path="/search/property" name="Property Search Page" element={<Property />} />
          <Route exact path="/search/properties" name="Property Search Page" element={<Property />} />
          <Route exact path="/property/:id/:slug" name="Property Page" element={<PropertyView />} />
          <Route exact path="/blog/:id/:slug" name="Post Page" element={<PostView />} />

          <Route exact path="/site/:id/:slug" name="Post Page" element={<MicroSite />} />

          <Route
            exact path="/login"
            name="Login Page"
            element={
              <LoginRoute>
                <Login />
              </LoginRoute>
            }
          />
          <Route
            exact path="/register"
            name="Register Page"
            element={
              <LoginRoute>
                <Register />
              </LoginRoute>
            }
          />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route
            path="*"
            element={
              userType === '1' ? (
                <ProtectedRoute>
                  <SuperAdminLayout />
                </ProtectedRoute>
              ) : (
                <ProtectedRoute>
                  <AdminLayout />
                </ProtectedRoute>
              )
            }
          />
        </Routes>
      </Suspense>
    </Router>
  )
}

export default App

import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';

const LoginRoute = ({ user, children }) => {
    let isAuthenticated = localStorage.getItem("isAuthenticated");
    let data = useSelector((state) => state.auth);
    if (isAuthenticated == 1) {
        if(data?.data?.user_type == 1){
            return <Navigate to="/admin/dashboard" replace />;
        } else {
            return <Navigate to="/dashboard" replace />;
        }
    }
    return children;
};

export default LoginRoute;
